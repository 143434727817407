import { mode } from '@chakra-ui/theme-tools';

export const globalStyles = {
    colors: {
        brand: {
            0: '#e1daca',
            50: '#c1b6a4',
            100: '#aea494',
            200: '#9a9283',
            300: '#877f73',
            400: '#746d62',
            500: '#615b52',
            600: '#4d4942',
            700: '#3a3731',
            800: '#272421',
            900: '#131210',
        },
        brandScheme: {
            50: '#f3f4ef',
            100: '#e1e4d7',
            200: '#ced3bd',
            300: '#bac1a3',
            400: '#abb38f',
            500: '#9ca67b',
            600: '#949e73',
            700: '#8a9568',
            800: '#808b5e',
            900: '#6e7b4b',
        },
        brandTabs: {
            100: '#E9E3FF',
            200: '#422AFB',
            300: '#422AFB',
            400: '#422AFB',
            500: '#422AFB',
            600: '#3311DB',
            700: '#02044A',
            800: '#190793',
            900: '#02044A'
        },
        secondaryGray: {
            100: '#f9f8f6',
            200: '#E1E9F8',
            300: '#F4F7FE',
            400: '#E9EDF7',
            500: '#8F9BBA',
            600: '#A3AED0',
            700: '#707EAE',
            800: '#707EAE',
            900: '#1B2559'
        },
        red: {
            100: '#FEEFEE',
            500: '#EE5D50',
            600: '#E31A1A'
        },
        blue: {
            50: '#EFF4FB',
            100: '#f5f7ff',
            500: '#3965FF'
        },
        orange: {
            100: '#FFF6DA',
            500: '#FFB547'
        },
        green: {
            100: '#E6FAF5',
            500: '#01B574'
        },
        navy: {
            50: '#d0dcfb',
            100: '#aac0fe',
            200: '#a3b9f8',
            300: '#728fea',
            400: '#3652ba',
            500: '#1b3bbb',
            600: '#24388a',
            700: '#1B254B',
            800: '#111c44',
            900: '#0b1437'
        },
        gray: {
            100: '#FAFCFE'
        },
    },
    styles: {
        global: (props: any) => ({
            body: {
                overflowX: 'hidden',
                bg: mode('secondaryGray.300', 'navy.900')(props),
                fontFamily: 'DM Sans',
                letterSpacing: '-0.5px'
            },
            input: {
                color: 'gray.700'
            },
            html: {
                fontFamily: 'DM Sans'
            }
        })
    }
};
