import React, { ReactElement, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useSystemToast } from '../../../ui/hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { useStrapi } from '../../../core/strapi/StrapiProvider';
import { RoutePath } from '../../../core/constant/route-path.enum';
import { SelectEvent } from '../select-event/SelectEvent';
import { If, Then, Else } from 'react-if';
import { UnpopulatedEvent } from '../../../core/api/models/event.model';
import { useSignInColors } from '../sign-in.colors';
import { useAtom } from 'jotai';
import { sessionAtom } from '../../../core/store/session.atom';
import { useSelectedEventAtom } from '../../../core/store/selected-event.atom';
import { Logger } from '../../../core/logger/Logger';

export const SignIn = (): ReactElement => {
    const { textColor, textColorSecondary } = useSignInColors();
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [showToast] = useSystemToast();
    const navigate = useNavigate();
    const { login } = useStrapi();
    const [session, setSession] = useAtom(sessionAtom);
    const { selectEvent } = useSelectedEventAtom();

    const handleSignIn = async (): Promise<void> => {
        try {
            const response = await login(email, password);
            if (response) {
                setSession(response);
                setLoggedIn(true);
            }
        } catch (error) {
            showToast({ title: 'Error while logging in', status: 'error' });
            console.error(error);
        }
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
    }

    const handlePasswordKeyUp = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            handleSignIn().catch(Logger.error);
        }
    }

    const handleClick = () => setShow(!show);

    const handleEventSelected = async (event: UnpopulatedEvent) => {
        try {
            selectEvent(event);
            navigate(RoutePath.adminDashboard);
        } catch (error) {
            Logger.error(error);
        }
    }

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <If condition={loggedIn}>
                <Then>
                    <SelectEvent eventSelected={handleEventSelected}/>
                </Then>
                <Else>
                    <Flex
                        maxW={{ base: '100%', md: 'max-content' }}
                        w="100%"
                        mx={{ base: 'auto', lg: '0px' }}
                        me="auto"
                        h="100%"
                        alignItems="start"
                        justifyContent="center"
                        mb={{ base: '30px', md: '60px' }}
                        px={{ base: '25px', md: '0px' }}
                        mt={{ base: '40px', md: '14vh' }}
                        flexDirection="column">
                        <Box me="auto">
                            <Heading color={textColor} fontSize="36px" mb="10px">
                                Sign In
                            </Heading>
                            <Text
                                mb="36px"
                                ms="4px"
                                color={textColorSecondary}
                                fontWeight="400"
                                fontSize="md">
                                Enter your email and password to sign in!
                            </Text>
                        </Box>
                        <Flex
                            zIndex="2"
                            direction="column"
                            w={{ base: '100%', md: '420px' }}
                            maxW="100%"
                            background="transparent"
                            borderRadius="15px"
                            mx={{ base: 'auto', lg: 'unset' }}
                            me="auto"
                            mb={{ base: '20px', md: 'auto' }}>
                            <FormControl>
                                <FormLabel
                                    display="flex"
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textColor}
                                    mb="8px">
                                    Email<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <Input
                                    value={email}
                                    onChange={handleEmailChange}
                                    isRequired={true}
                                    variant="auth"
                                    fontSize="sm"
                                    ms={{ base: '0px', md: '0px' }}
                                    type="email"
                                    placeholder="mail@simmmple.com"
                                    mb="24px"
                                    fontWeight="500"
                                    size="lg"
                                />
                                <FormLabel
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textColor}
                                    display="flex">
                                    Password<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <InputGroup size="md">
                                    <Input
                                        value={password}
                                        onChange={handlePasswordChange}
                                        onKeyUp={handlePasswordKeyUp}
                                        isRequired={true}
                                        fontSize="sm"
                                        placeholder="Min. 8 characters"
                                        mb="24px"
                                        size="lg"
                                        type={show ? 'text' : 'password'}
                                        variant="auth"
                                    />
                                    <InputRightElement display="flex" alignItems="center" mt="4px">
                                        <Icon
                                            color={textColorSecondary}
                                            _hover={{ cursor: 'pointer' }}
                                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                            onClick={handleClick}
                                        />
                                    </InputRightElement>
                                </InputGroup>
                                <Flex justifyContent="space-between" align="center" mb="24px">
                                    <FormControl display="flex" alignItems="center">
                                        <Checkbox
                                            id="remember-login"
                                            colorScheme="brandScheme"
                                            me="10px"
                                        />
                                        <FormLabel
                                            htmlFor="remember-login"
                                            mb="0"
                                            fontWeight="normal"
                                            color={textColor}
                                            fontSize="sm">
                                            Keep me logged in
                                        </FormLabel>
                                    </FormControl>
                                </Flex>
                                <Button
                                    onClick={handleSignIn}
                                    fontSize="sm"
                                    variant="brand"
                                    backgroundColor="brand.50"
                                    _hover={{ backgroundColor: 'brand.100' }}
                                    fontWeight="500"
                                    w="100%"
                                    h="50"
                                    mb="24px">
                                    Sign In
                                </Button>
                            </FormControl>
                        </Flex>
                    </Flex>
                </Else>
            </If>
        </DefaultAuth>
    );
}

