import {
    MdLock,
    MdGroup,
    MdEvent,
    MdSettings,
    MdMenuBook,
    MdOutlineAirlineSeatReclineExtra,
    MdOutlineAnnouncement,
    MdOutlineHotel,
    MdOutlineHome,
    MdOutlineVideogameAsset,
    MdOutlinePhoto,
    MdOutlineQuestionMark,
    MdOutlineSchedule,
    MdOutlineList,
    MdPeople,
    MdCelebration, MdOutlineMiscellaneousServices
} from 'react-icons/md';
import { CiCalendarDate } from 'react-icons/ci';
import { SignIn } from 'views/auth/signIn';
import { RouteIcon } from './components/icons/route/RouteIcon';
import Guests from './views/admin/guests/Guests';
import Event from './views/admin/event/Event';
import { Announcements } from './views/admin/announcements/announcements';
import { Home } from './views/admin/home/Home';
import { Navigate } from 'react-router-dom';
import React from 'react';
import MenuPlan from './views/admin/menuPlan/MenuPlan';
import SeatingPlan from './views/admin/seatingPlan/SeatingPlan';
import { Accommodation } from './views/admin/accommodation/Accommodation';
import { Game } from './views/admin/game/Game';
import { Questions } from './views/admin/questions/Questions';
import { Photos } from './views/admin/photos/Photos';
import { Schedule } from './views/admin/schedule/Schedule';
import { WishList } from './views/admin/wishList/WishList';
import SaveTheDate from './views/admin/saveTheDate/SaveTheDate';
import { GuestsGroups } from './views/admin/guestGroups/GuestsGroups';
import { StagParty } from './views/stagParty/StagParty';
import { ServiceProvider } from './views/admin/serviceProvider/ServiceProvider';
import { t } from 'i18next';
import Settings from './views/admin/settings/Settings';
import Profile from 'views/admin/profile';

const useRoutes = (): Array<RoutesType> => [
    {
        name: t('menu.item.home', { defaultValue: 'Home' }),
        layout: '/admin',
        path: '/home',
        icon: <RouteIcon icon={MdOutlineHome}/>,
        component: <Home/>,
        sidebar: true,
    },
    {
        name: t('menu.item.home', { defaultValue: 'Home' }),
        component: <Navigate to="/home"/>,
        index: true,
        sidebar: false,
        layout: '/admin',
        icon: <RouteIcon icon={MdOutlineHome}/>,
    },
    {
        name: t('menu.item.accommodations', { defaultValue: 'Accommodations' }),
        layout: '/admin',
        path: '/accommodations',
        icon: <RouteIcon icon={MdOutlineHotel}/>,
        component: <Accommodation/>,
        sidebar: true
    },
    {
        name: t('menu.item.announcements', { defaultValue: 'Announcements' }),
        layout: '/admin',
        path: '/announcements',
        icon: <RouteIcon icon={MdOutlineAnnouncement}/>,
        component: <Announcements/>,
        sidebar: true
    },
    {
        name: t('menu.item.event', { defaultValue: 'Event' }),
        layout: '/admin',
        path: '/event',
        icon: <RouteIcon icon={MdEvent}/>,
        component: <Event/>,
        sidebar: true
    },
    {
        name: t('menu.item.games', { defaultValue: 'Games' }),
        layout: '/admin',
        path: '/games',
        icon: <RouteIcon icon={MdOutlineVideogameAsset}/>,
        component: <Game/>,
        sidebar: true
    },
    {
        name: t('menu.item.guests', { defaultValue: 'Guests' }),
        layout: '/admin',
        path: '/guests',
        icon: <RouteIcon icon={MdPeople}/>,
        component: <Guests/>,
        sidebar: true
    },
    {
        name: t('menu.item.guestGroups', { defaultValue: 'Guest Groups' }),
        layout: '/admin',
        path: '/guestgroups',
        icon: <RouteIcon icon={MdGroup}/>,
        component: <GuestsGroups/>,
        sidebar: true
    },
    {
        name: t('menu.item.menuPlan', { defaultValue: 'Menu Plan' }),
        layout: '/admin',
        path: '/menu-plan',
        icon: <RouteIcon icon={MdMenuBook}/>,
        component: <MenuPlan/>,
        sidebar: true
    },
    {
        name: t('menu.item.photos', { defaultValue: 'Photos' }),
        layout: '/admin',
        path: '/photos',
        icon: <RouteIcon icon={MdOutlinePhoto}/>,
        component: <Photos/>,
        sidebar: true
    },
    {
        name: t('menu.item.questions', { defaultValue: 'Questions' }),
        layout: '/admin',
        path: '/questions',
        icon: <RouteIcon icon={MdOutlineQuestionMark}/>,
        component: <Questions/>,
        sidebar: true
    },
    {
        name: t('menu.item.saveTheDate', { defaultValue: 'Save The Date' }),
        layout: '/admin',
        path: '/save-the-date',
        icon: <RouteIcon icon={CiCalendarDate}/>,
        component: <SaveTheDate/>,
        sidebar: true
    },
    {
        name: t('menu.item.schedule', { defaultValue: 'Schedule' }),
        layout: '/admin',
        path: '/schedule',
        icon: <RouteIcon icon={MdOutlineSchedule}/>,
        component: <Schedule/>,
        sidebar: true
    },
    {
        name: t('menu.item.seatingPlan', { defaultValue: 'Seating Plan' }),
        layout: '/admin',
        path: '/seating-plan',
        icon: <RouteIcon icon={MdOutlineAirlineSeatReclineExtra}/>,
        component: <SeatingPlan/>,
        sidebar: true
    },
    {
        name: t('menu.item.serviceProvider', { defaultValue: 'Service Provider' }),
        layout: '/admin',
        path: '/service-provider',
        icon: <RouteIcon icon={MdOutlineMiscellaneousServices}/>,
        component: <ServiceProvider/>,
        sidebar: true
    },
    {
        name: t('menu.item.settings', { defaultValue: 'Settings' }),
        layout: '/admin',
        path: '/settings',
        icon: <RouteIcon icon={MdSettings}/>,
        component: <Settings/>,
        sidebar: true
    },
    {
        name: t('menu.item.stagParty', { defaultValue: 'Stag Party' }),
        layout: '/admin',
        path: '/stag-party',
        icon: <RouteIcon icon={MdCelebration}/>,
        component: <StagParty/>,
        sidebar: true
    },
    {
        name: t('menu.item.wishlist', { defaultValue: 'Wishlist' }),
        layout: '/admin',
        path: '/wishlist',
        icon: <RouteIcon icon={MdOutlineList}/>,
        component: <WishList/>,
        sidebar: true
    },
    {
        name: t('menu.item.signIn', { defaultValue: 'Sign In' }),
        layout: '/auth',
        path: '/sign-in',
        icon: <RouteIcon icon={MdLock}/>,
        component: <SignIn/>,
        sidebar: false
    },
    {
        name: t('menu.item.profile', { defaultValue: 'Profile' }),
        layout: '/admin',
        path: '/profile',
        icon: <RouteIcon icon={MdLock}/>,
        component: <Profile/>,
        sidebar: false
    }
];

export default useRoutes;
