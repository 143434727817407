import { Box, Center, Divider, Flex } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAuth';
import React from 'react';
import { Navbar } from './components/navbar/Navbar';
import { Contact } from './components/contact/Contact';
import Pricing from './components/pricing/Pricing';
import PhotoSlider from './components/photoSlider/PhotoSlider';
import Features from './components/features/Features';
import Intro from './components/intro/Intro';
import { SectionDivider } from './components/divider/SectionDivider';

function LandingPage() {
    return (
        <Flex position="relative" h="max-content">
            <Flex
                h={{
                    sm: 'initial',
                    md: 'unset',
                    lg: '100vh',
                    xl: '97vh'
                }}
                w="100%"
                mx="auto"
                justifyContent="start"
                direction="column">
                <Navbar/>
                <Box className={'landing-page-content-wrapper'}
                     h="100%"
                     minH="100vh"
                     width="100%"
                     mt={'4em'}
                     right="0px">
                    <Box mx="auto" minH="100vh" className={'landing-page-content'}>
                        <Intro/>
                        <SectionDivider/>
                        <Features/>
                        <SectionDivider/>
                        <PhotoSlider/>
                        <SectionDivider/>
                        <Pricing/>
                        <SectionDivider/>
                        <Contact/>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Footer/>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default LandingPage;
