import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';
import { useSelectedEventAtom } from '../store/selected-event.atom';
import { useEventApi } from '../api/event.api';
import { useEffect } from 'react';
import { UnpopulatedEvent } from '../api/models/event.model';

export const ProtectedRoute = ({ children }: { children: any }) => {
    const { user } = useAuth();
    const { selectEvent, selectedEvent } = useSelectedEventAtom();
    const { fetchEvent } = useEventApi();

    useEffect(() => {
        if (selectedEvent?.id) {
            fetchEvent().then(event => {
                selectEvent(event as unknown as UnpopulatedEvent);
            });
        }
    }, [fetchEvent, selectEvent, selectedEvent]);

    if (!user) {
        return <Navigate to="/auth/sign-in"/>;
    }
    return children;
};
