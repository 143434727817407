import { Box, Flex, Stack } from '@chakra-ui/react';
import Links from 'components/sidebar/components/Links';
import { ReactElement } from 'react';


function SidebarContent(props: { routes: RoutesType[] }): ReactElement {
    const { routes } = props;
    return (
        <Flex direction="column" height="100%" pt="25px" borderRadius="30px" mt={4}>
            <Stack direction="column" mt="8px" mb="auto" overflowY={'scroll'}>
                <Box ps="20px" pe={{ lg: '16px', '2xl': '16px' }} mt={10}>
                    <Links routes={routes}/>
                </Box>
            </Stack>
        </Flex>
    );
}

export default SidebarContent;
