import { Logger } from '../logger/Logger';
import { useToast } from '@chakra-ui/react';

export const useDataToasts = (): DataToastType => {
    const defaultToastDuration = 2000;
    const toast = useToast();

    const handleError = (error: unknown, message?: string): void => {
        Logger.error(error);
        toast({
            title: 'Error',
            description: message ? message : '',
            status: 'error',
            duration: defaultToastDuration
        });
    }

    const handleSaveSuccess = (message?: string): void => {
        toast({
            title: 'Success',
            description: message ? message : '',
            status: 'success',
            duration: defaultToastDuration
        });
    };

    return { handleError, handleSaveSuccess };
}

export type DataToastType = {
    handleError?: (error: unknown, message?: string) => void,
    handleSaveSuccess?: (message?: string) => void
}
