import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAuth';
import React, { useCallback, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Header } from './components/Header';
import { useSearchParams } from 'react-router-dom';
import { useEventApi } from '../../core/api/event.api';
import { DataLoader } from '../../components/loading/DataLoader';
import { Logger } from '../../core/logger/Logger';
import { useAppSettingsApi } from '../../core/api/appSettings/app-settings.api';
import { PhotoBoxGallery } from './components/photoBox/PhotoBoxGallery';
import { PhotoGallery } from './components/photoGallery/PhotoGallery';

function PhotosPage() {
    const [searchParams] = useSearchParams();
    const { fetchEventById, data, loading, error } = useEventApi();
    const { fetchAppSetting, data: appSettings } = useAppSettingsApi();

    useEffect(() => {
        const id = searchParams.get('id');
        init(id);
    }, []);

    const init = useCallback(async (eventId: string) => {
        try {
            const event = await fetchEventById(eventId, ['app_setting', 'images']);
            await fetchAppSetting(event.attributes.app_setting.data.id);
        } catch (error) {
            Logger.error('Error while fetching event', error);
        }
    }, [fetchAppSetting, fetchEventById]);

    if (error) {
        return <Box>Error</Box>
    }

    if (loading || !data || !appSettings) {
        return <DataLoader/>
    }

    return (
        <Flex position="relative" h="max-content">
            <Flex
                h={{
                    sm: 'initial',
                    md: 'unset',
                    lg: '100vh',
                    xl: '97vh'
                }}
                w="100%"
                mx="auto"
                justifyContent="start"
                direction="column">
                <Header title={data.attributes.name}/>
                <Box className={'landing-page-content-wrapper'}
                     h="100%"
                     minH="100vh"
                     width="100%"
                     mt={'5em'}
                     right="0px">
                    <Box mx="auto" minH="100vh" className={'landing-page-content'}>
                        <Tabs minW="100%">
                            <TabList>
                                <Tab><Trans i18nKey={'page.photos.all'}>Photos</Trans></Tab>
                                <Tab><Trans i18nKey={'page.photos.photobox'}>Photobox</Trans></Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <PhotoGallery images={data.attributes.images.data}/>
                                </TabPanel>
                                <TabPanel>
                                    <PhotoBoxGallery appSetting={appSettings}/>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Footer/>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default PhotosPage;
