export const formControlStyles = {
    components: {
        Form: {
            baseStyle: {
                container: {
                    marginTop: '1em'
                }
            },
        }
    }
};
