import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useRoutes from 'routes';

export default function Dashboard(props: { [x: string]: any }) {
    const { ...rest } = props;
    const routes = useRoutes();
    // states and functions
    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const location = useLocation();

    const getRoute = () => {
        return location.pathname;
    };
    const getActiveRoute = (routes: RoutesType[]): string => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes: RoutesType[]): boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].secondary;
            }
        }
        return activeNavbar;
    };
    const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
                return routes[i].name;
            }
        }
        return activeNavbar;
    };

    document.documentElement.dir = 'ltr';
    const { onOpen } = useDisclosure();

    return (
        <Box>
            <SidebarContext.Provider
                value={{
                    toggleSidebar,
                    setToggleSidebar
                }}>
                <Sidebar routes={routes} display="none" {...rest} />
                <Box
                    float="right"
                    minHeight="80vh"
                    height="100%"
                    overflow="auto"
                    position="relative"
                    maxHeight="100%"
                    w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
                    maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
                    transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                    transitionDuration=".2s, .2s, .35s"
                    transitionProperty="top, bottom, width"
                    transitionTimingFunction="linear, linear, ease">
                    <Portal>
                        <Box>
                            <Navbar
                                onOpen={onOpen}
                                logoText={'Meant To Bee'}
                                brandText={getActiveRoute(routes)}
                                secondary={getActiveNavbar(routes)}
                                message={getActiveNavbarText(routes)}
                                fixed={fixed}
                                {...rest}
                            />
                        </Box>
                    </Portal>

                    {getRoute() ? (
                        <>
                            <Box mx="auto" p={{ base: '20px', md: '30px' }} pe="20px" minH="100%" pt="50px">
                                <Outlet/>
                            </Box>

                        </>
                    ) : null}
                    <Box>
                        <Footer/>
                    </Box>
                </Box>
            </SidebarContext.Provider>
        </Box>
    );
}
