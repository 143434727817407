import React from 'react';
import './assets/css/App.css';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from './core/auth/AuthProvider';
import { StrapiProvider } from './core/strapi/StrapiProvider';
import { initLogger } from 'core/logger/Logger';
import { App } from './App';


const container = document.getElementById('root');
const root = createRoot(container!);
initLogger();

root.render(<ChakraProvider theme={theme}>
    <React.StrictMode>
        <AuthProvider>
            <StrapiProvider>
                <App/>
            </StrapiProvider>
        </AuthProvider>
    </React.StrictMode>
</ChakraProvider>);
