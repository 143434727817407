import {
    Avatar,
    Button,
    Flex,
    Icon,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    useColorMode, Select, useToast
} from '@chakra-ui/react';
import { ItemContent } from 'components/menu/ItemContent';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdLanguage, MdNotificationsNone } from 'react-icons/md';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { useLoggedInUserAtom } from '../../core/store/loggedIn-user.atom';
import { useAtom } from 'jotai';
import { notificationAtom } from '../../core/store/notification.atom';
import { LoggedInUser } from '../../core/store/models/loggedIn-user.model';
import { Trans } from 'react-i18next';
import useRoutes from 'routes';
import { UnpopulatedEvent } from '../../core/api/models/event.model';
import { useStrapi } from '../../core/strapi/StrapiProvider';
import { Logger } from '../../core/logger/Logger';
import { useSelectedEventAtom } from '../../core/store/selected-event.atom';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../core/constant/route-path.enum';
import { t } from 'i18next';

export default function HeaderLinks(props: { secondary: boolean }) {
    const { secondary } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    const routes = useRoutes();
    // Chakra Color Mode
    const navbarIcon = useColorModeValue('gray.400', 'white');
    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorBrand = useColorModeValue('brand.700', 'brand.400');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
    );
    const { getLoggedInUser } = useLoggedInUserAtom();
    const [events, setEvents] = useState<Array<UnpopulatedEvent>>([]);

    //const [{ username }] = useAtom(loggedInUserAtom);
    const [notifications] = useAtom(notificationAtom);
    const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | null>(null);
    const { getEvents, logout } = useStrapi();
    const { selectedEventId, selectEvent } = useSelectedEventAtom();
    const toast = useToast();

    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            const eventsForDatabase = await getEvents();
            setEvents(eventsForDatabase);
        }
        init().catch(Logger.error);
    }, []);

    useEffect(() => {
        getLoggedInUser().then((user) => {
            setLoggedInUser(user);
        });
    }, [getLoggedInUser]);

    const handleEventChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value) {
            try {
                const selectedEvent = events.find((e) => e.id === parseInt(event.target.value));
                if (selectedEvent) {
                    selectEvent(selectedEvent);
                    navigate(RoutePath.adminDashboard);
                }
            } catch (error) {
                Logger.error(error);
            }
        } else {
            toast({
                title: t('event.select.noEventSelected', { defaultValue: 'No event selected' }),
                description: t('event.select.selectEvent', { defaultValue: 'Please select an event to proceed' }),
                status: 'info'
            });
        }
    };

    const handleLogout = async (): Promise<void> => {
        try {
            await logout();
            navigate(RoutePath.signIn, { replace: true });
        } catch (error) {
            Logger.error(error);
        }
    };

    return (
        <Flex flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
            <Select borderColor={'brand.100'} value={selectedEventId} _hover={{ borderColor: 'brand.500' }} placeholder="Select Event"
                    mr={4}
                    onChange={handleEventChange}>
                {
                    events.map((event: UnpopulatedEvent) => {
                        return <option key={event.id} value={event.id}>{event.name}</option>
                    })
                }
            </Select>
            <Flex
                w={{ sm: '100%', md: 'auto' }}
                alignItems="center"
                flexDirection="row"
                bg={menuBg}
                flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
                p="10px"
                borderRadius="30px"
                boxShadow={shadow}>
                <SidebarResponsive routes={routes}/>
                <Menu>
                    <MenuButton p="0px">
                        <Icon mt="6px" as={MdLanguage} color={navbarIcon} w="18px" h="18px" me="10px"/>
                    </MenuButton>
                    <MenuList
                        boxShadow={shadow}
                        p="20px"
                        borderRadius="20px"
                        bg={menuBg}
                        border="none"
                        flexDirection={'column'}
                        display={'flex'}
                        mt="22px">
                        <Button variant="solid" mt={'1em'}>
                            <Trans i18nKey="general.languages.german">German</Trans>
                        </Button>
                        <Button variant="solid" mt={'1em'}>
                            <Trans i18nKey="general.languages.english">English</Trans>
                        </Button>
                    </MenuList>
                </Menu>
                <Menu>
                    <MenuButton p="0px">
                        <Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px"/>
                    </MenuButton>
                    <MenuList
                        boxShadow={shadow}
                        p="20px"
                        borderRadius="20px"
                        bg={menuBg}
                        border="none"
                        mt="22px"
                        me={{ base: '30px', md: 'unset' }}
                        minW={{ base: 'unset', md: '400px', xl: '450px' }}
                        maxW={{ base: '360px', md: 'unset' }}>
                        <Flex w="100%" mb="20px">
                            <Text fontSize="md" fontWeight="600" color={textColor}>
                                Notifications
                            </Text>
                            <Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
                                Mark all read
                            </Text>
                        </Flex>
                        <Flex flexDirection="column">
                            {notifications.map((notification, index) => (
                                <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
                                    <ItemContent info={notification.title}/>
                                </MenuItem>
                            ))}
                        </Flex>
                    </MenuList>
                </Menu>

                <Button
                    variant="no-hover"
                    bg="transparent"
                    p="0px"
                    minW="unset"
                    minH="unset"
                    h="18px"
                    w="max-content"
                    onClick={toggleColorMode}>
                    <Icon
                        me="10px"
                        h="18px"
                        w="18px"
                        color={navbarIcon}
                        as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
                    />
                </Button>
                <Menu>
                    <MenuButton p="0px">
                        <Avatar
                            _hover={{ cursor: 'pointer' }}
                            color="white"
                            name="Adela Parkson"
                            bg="#11047A"
                            size="sm"
                            w="40px"
                            h="40px"
                        />
                    </MenuButton>
                    <MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
                        <Flex w="100%" mb="0px">
                            <Text
                                ps="20px"
                                pt="16px"
                                pb="10px"
                                w="100%"
                                borderBottom="1px solid"
                                borderColor={borderColor}
                                fontSize="sm"
                                fontWeight="700"
                                color={textColor}>
                                👋&nbsp; Hey, {loggedInUser && loggedInUser.username ? loggedInUser.username : ''}
                            </Text>
                        </Flex>
                        <Flex flexDirection="column" p="10px">
                            <MenuItem
                                href={'/#/admin/profile'}
                                as={Link}
                                _hover={{ bg: 'none' }}
                                _focus={{ bg: 'none' }}
                                borderRadius="8px"
                                px="14px">
                                <Text fontSize="sm">Profile</Text>
                            </MenuItem>
                            <MenuItem
                                onClick={handleLogout}
                                _hover={{ bg: 'none' }}
                                _focus={{ bg: 'none' }}
                                color="red.400"
                                borderRadius="8px"
                                px="14px">
                                <Text fontSize="sm">Log out</Text>
                            </MenuItem>
                        </Flex>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func
};
