import React, { ReactElement, useCallback } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

export const FileUpload = (props: FileUploadProps): ReactElement => {
    const { filesSelected, multiple } = props;
    const onDrop = useCallback((acceptedFiles: Array<File>) => {
        filesSelected(acceptedFiles);
    }, [filesSelected]);

    const { content, ...rest } = props;
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple });

    return (
        <Box
            display={'flex'}
            justifyContent="center"
            alignItems={'center'}
            borderRadius="16px"
            w="100%"
            minH={'25vh'}
            cursor="pointer"
            {...getRootProps({ className: 'dropzone' })}
            {...rest}>
            <input {...getInputProps()} />
            <Button variant="no-effects">{content}</Button>
        </Box>
    );
}


export type FileUploadProps = {
    content: JSX.Element | string; [x: string]: any;
    filesSelected: (file: Array<File>) => void;
    multiple?: boolean;
}

FileUpload.defaultProps = {
    multiple: false
}
